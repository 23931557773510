import ReactGTM from "react-gtm-module";

interface EventProps {
  event?: string;
  signal: string;
  prd_oferta?: string;
  tipo_signal: string;
  prd?: string;
  prd_sub?: string;
  op?: string;
  canal?: string;
  nup?: string;
}

interface ViewProps {
  event?: string;
  signal?: string;
  prd?: string;
  canal?: string;
  nup?: string;
  prd_sub?: string;
  op?: string;
  screen?: string;
}

export const getEventTag = (prop: EventProps) => {
  ReactGTM.dataLayer({
    dataLayer: {
      event: "webSignals",
      prd: "formularios",
      canal: "wsr",
      prd_sub: "work_cafe",
      op: "pedido_wifi",
      signal: prop.signal,
      tipo_signal: prop.tipo_signal,
    },
  });
};

export const getViewTag = (prop: ViewProps) => {
  ReactGTM.dataLayer({
    dataLayer: {
      event: "webSignals",
      prd: "formularios",
      canal: "wsr",
      prd_sub: "work_cafe",
      op: "pedido_wifi",
      signal: prop.signal,
      screen: prop.screen,
    },
  });
};
