import CSSReset from "@flame-ui/utils/css-reset";
import ThemeProvider from "@flame-ui/utils/theme-provider";
import peopleTheme from "@flame-ui/themes/people";
import Home from "app/pages/Home";
import "./globalStyles.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PageSuccess from "app/components/PageSuccess";
import PageError from "app/components/PageError";
import { GoogleTagManager } from "app/components/GTM/googleTagManager";
import { GlobalProvider } from "./app/contexts/Context";

export default function App() {
  return (
    <>
      <CSSReset />
      <ThemeProvider theme={peopleTheme}>
        <GlobalProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path={"/"} component={Home} />
              <Route exact path={"/success"} component={PageSuccess} />
              <Route exact path={"/error"} component={PageError} />
            </Switch>
          </BrowserRouter>
          <GoogleTagManager />
        </GlobalProvider>
      </ThemeProvider>
    </>
  );
}
