import styled from "styled-components";

export const Container = styled.div`
  margin-top: 5vh;
`;
export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 34px;
  gap: 34px;
`;
