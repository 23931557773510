import React, { ChangeEvent, useState, useEffect } from "react";
import {
  ButtonForm,
  CheckboxContainer,
  Form,
  StyledLabel,
  SubtitleForm,
  TermsButton,
  VisuallyHiddenLabel,
  Wrapper,
} from "./loginForm.styles";
import TextField from "@flame-ui/core/textField";
import Checkbox from "@flame-ui/core/checkbox";
import ModalInfo from "../Modal/Modal";
import WarningAlert from "../WarningAlert";
import { getEventTag, getViewTag } from "../../utils/getGTMtag";
import { formatCUIT, validateField } from "../../utils/validationUtils";

export interface DatosForm {
  email: string;
  cuit: string;
}

interface ErrorFormulario {
  email?: string;
  cuit?: string;
}

interface FormularioProps {
  titulo: string;
  onSubmit: (datosForm: DatosForm) => void;
}

const LoginForm: React.FC<FormularioProps> = ({ titulo, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [cuit, setCuit] = useState("");
  const [alertTermsAccepted, setAlertTermsAccepted] = useState(false);
  const [errors, setErrors] = useState<ErrorFormulario>({
    cuit: "",
    email: "",
  });

  const [firstTag, setFirstTag] = useState(false);
  useEffect(() => setFirstTag(true), []);
  useEffect(() => {
    if (firstTag) {
      getViewTag({
        screen: "home_workcafe",
        signal: "screen_view",
      });
    }
  }, [firstTag]);

  const handleInputChange = (field: "email" | "cuit", value: string) => {
    if (field === "email") {
      setEmail(value);
    }
    if (field === "cuit") {
      const formattedValue = formatCUIT(value);
      setCuit(formattedValue);
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: validateField(field, value),
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const emailError = validateField("email", email);
    const cuitError = validateField("cuit", cuit);
    setErrors({ email: emailError, cuit: cuitError });

    getEventTag({
      signal: "pedido_internet",
      tipo_signal: "interaccion",
    });

    if (!emailError && !cuitError && termsAccepted) {
      onSubmit({ email, cuit });
      setAlertTermsAccepted(false);
    } else if (!emailError && !cuitError && !termsAccepted) {
      setAlertTermsAccepted(true);
    }
  };

  return (
    <>
      <Wrapper>
        <SubtitleForm>Ingrese sus datos y acceda al Wifi</SubtitleForm>
        <Form onSubmit={handleSubmit}>
          <VisuallyHiddenLabel htmlFor="cuit">Cuil/Cuit</VisuallyHiddenLabel>
          <TextField
            id="cuit"
            placeholder="CUIL/CUIT"
            value={cuit}
            error={errors.cuit}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange("cuit", e.target.value)
            }
            aria-labelledby={errors.cuit ? "cuitError" : ""}
          />
          <VisuallyHiddenLabel htmlFor="email">Email</VisuallyHiddenLabel>
          <TextField
            id="email"
            placeholder="Email"
            data-testid="email"
            value={email}
            error={errors.email}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange("email", e.target.value)
            }
            aria-labelledby={errors.email ? "emailError" : ""}
          />
          <CheckboxContainer>
            <Checkbox
              id="pepe"
              data-testid="pepe"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            <StyledLabel htmlFor="termsCheckbox">
              {" "}
              Al ingresar, estás aceptando los
              <TermsButton
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
              >
                {" "}
                Términos y condiciones.
              </TermsButton>
            </StyledLabel>
          </CheckboxContainer>
          {alertTermsAccepted && <WarningAlert />}
          <ButtonForm type="submit" size="small" variant="primary">
            Iniciar sesión
          </ButtonForm>
        </Form>
      </Wrapper>
      {showModal && (
        <ModalInfo
          onClose={() => setShowModal(false)}
          content="El titular de los datos personales presta su consentimiento libre, expreso e informado para que los datos provistos sean utilizados y tratados en la base de datos de Banco Santander  S.A. con domicilio sito en la calle Av. Juan de Garay 151 C.A.B.A. Los mismos son recabados con la finalidad de ofrecer y/o publicitar bienes, productos y/o servicios que son objeto de la actividad que desarrolla. Las respuestas incluidas en el presente formulario revisten el carácter de facultativas para quien lo suscribe. Se informa que el interesado podrá en cualquier momento ejercer los derechos de acceso, rectificación y supresión de sus datos. La AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, Órgano de Control de la Ley Nº 25.326, atiende las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales."
        />
      )}
    </>
  );
};

export default LoginForm;
