import headerImage from "../../images/logo.svg";
import LoginForm, { DatosForm } from "../components/LoginForm/LoginForm";
import Typography from "@flame-ui/core/typography";
import { Container, StyledHeader } from "./Home.styles";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { GlobalState } from "../contexts/Context";
import axios from "axios";

const HomePage = () => {
  const history = useHistory();
  const { setState } = useContext(GlobalState);
  const params = new URLSearchParams(document.location.search);
  const baseURL = params.get("base_grant_url");
  const nodeMac = params.get("node_mac") || "";
  const nodeId = params.get("node_id") || "";

  const handleFormSubmit = async (datosForm: DatosForm) => {
    const data = {
      ...datosForm,
      cuit: datosForm.cuit.split("-").join(""),
      mac_address: nodeMac,
    };

    console.log(data);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/users`, data)
      .then(({ data }) => {
        console.log("data", data);
        const successURL = data?.isClient
          ? `https://www.santander.com.ar?utm_source=form_wifi&utm_medium=workcafe&utm_campaign=home`
          : "https://www.santander.com.ar/personas/tarjetas/saca-tu-tarjeta-ideal?utm_source=form_wifi&utm_medium=workcafe&utm_campaign=tarjeta_ideal";
        const targetUrl = `${baseURL}?node_mac=${encodeURIComponent(
          nodeMac
        )}&node_id=${encodeURIComponent(
          nodeId
        )}&continue_url=${encodeURIComponent(successURL)}`;

        setState({
          targetUrl: encodeURIComponent(targetUrl),
        });
        history.push(`/success`);
      })
      .catch((error) => history.push("/error"));
  };

  return (
    <>
      <Container>
        <StyledHeader>
          <img src={headerImage} alt="Work Cafe Santander" />
          <Typography tag="h1" size="titleM" bold>
            ¡Te damos la bienvenida!
          </Typography>
        </StyledHeader>
        <LoginForm
          titulo="Ingrese sus datos y acceda al Wifi"
          onSubmit={handleFormSubmit}
        />
      </Container>
    </>
  );
};

export default HomePage;
