import { CloseButton } from "@flame-ui/core/modal";
import { ModalContainer, TextoModal } from "./modal.styles";
import Button from "@flame-ui/core/button";

interface ModalInfoProps {
  onClose: () => void;
  content: string; // Definimos la prop onClose
}

const ModalInfo: React.FC<ModalInfoProps> = ({ onClose, content }) => {
  return (
    <>
      <ModalContainer
        data-testid="modalContainer"
        show={true}
        onClose={onClose}
        title="Términos y condiciones"
        primaryButton={<Button onClick={() => onClose()}>Entendido</Button>}
        closeButton={
          <CloseButton
            data-testid="customCloseID"
            id="customCloseID"
            onClose={() => onClose()}
          />
        }
        variant="content_large"
      >
        <TextoModal>{content}</TextoModal>
      </ModalContainer>
    </>
  );
};

export default ModalInfo;
