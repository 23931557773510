import Button from "@flame-ui/core/src/buttons/button/Button";
import Typography from "@flame-ui/core/src/foundations/typography/Typography";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 200px;
  text-align: center;
  @media (max-width: 1224px) and (min-width: 900px) {
  }

  @media (max-width: 899px) and (min-width: 675px) {
  }

  @media (max-width: 674px) {
  }
`;
export const TitleSuccess = styled(Typography)`
  margin: 20px 25px 10px 25px;

  @media (max-width: 674px) {
    margin-top: 40px;
    font-size: 20px;
  }
`;
export const SubtitleSuccess = styled(Typography)`
  margin: 25px 10px 50px 10px;
  @media (max-width: 1224px) and (min-width: 900px) {
  }

  @media (max-width: 899px) and (min-width: 675px) {
  }

  @media (max-width: 674px) {
    font-size: 15px;
    margin: 25px 10px 40px 10px;
  }
`;

export const ButtonSuccess = styled(Button)`
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: max-content;
  align-self: center;

  @media (max-width: 1224px) and (min-width: 900px) {
    font-size: 15px;
  }

  @media (max-width: 899px) and (min-width: 675px) {
    font-size: 13px;
  }

  @media (max-width: 674px) {
    font-size: 12px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const InfoSuccess = styled(Typography)`
  margin: 25px 10px 50px 10px;
  @media (max-width: 1224px) and (min-width: 900px) {
  }

  @media (max-width: 899px) and (min-width: 675px) {
  }

  @media (max-width: 674px) {
    font-size: 15px;
    margin: 25px 10px 40px 10px;
  }
`;
