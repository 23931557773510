import React, { useContext, useEffect } from "react";
import CheckmarkInACircle from "@flame-ui/icons/src/checkmark-in-a-circle";
import { Container, SubtitleSuccess, TitleSuccess } from "./PageSuccess.styles";
import { getViewTag } from "../../utils/getGTMtag";
import { GlobalState } from "../../contexts/Context";

const PageSuccess: React.FC = () => {
  const { state } = useContext(GlobalState);
  const targetUrl = state.targetUrl;

  useEffect(() => {
    setTimeout(() => {
      if (targetUrl) {
        window.location.href = decodeURIComponent(targetUrl);
      }
    }, 3000);
  }, [targetUrl]);

  useEffect(() => {
    getViewTag({
      screen: "screen_view",
      signal: "pedido_exitoso",
    });
  });

  return (
    <>
      <Container>
        <CheckmarkInACircle
          color="rgba(0, 132, 55, 1)"
          size={5}
          onPointerEnterCapture={undefined}
        />
        <TitleSuccess size="titleS" bold>
          ¡Listo!
        </TitleSuccess>
        <SubtitleSuccess size="paragraphL" bold>
          Ya te conectaste al WiFi gratis.
        </SubtitleSuccess>
      </Container>
    </>
  );
};

export default PageSuccess;
