export const formatCUIT = (cuit: string): string => {
  let numericCuit = cuit.replace(/\D/g, "");
  if (numericCuit.length > 11) {
    numericCuit = numericCuit.slice(0, 11);
  }

  const formattedCuit = numericCuit.replace(/^(\d{2})(\d{8})(\d)$/, "$1-$2-$3");

  return formattedCuit;
};

export const validarCuit = (cuit: string): string => {
  if (cuit.length !== 13) return "Tu CUIL/CUIT debe tener 11 dígitos numéricos";

  const numericCuit = cuit.replace(/[^0-9]/g, "");
  //Validación si es un extranjero (numero de cuit fijo y ramdom)
  if (numericCuit === "99001982239") {
    return "";
  } else {
    const tiposValidos = ["20", "23", "24", "25", "26", "27", "30", "33", "34"];
    if (!tiposValidos.includes(numericCuit.substring(0, 2)))
      return "El tipo de CUIT/CUIL no es válido.";

    const multiplicadores = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    let suma = 0;
    for (let i = 0; i < 10; i++) {
      suma += parseInt(numericCuit[i], 10) * multiplicadores[i];
    }

    const resto = suma % 11;
    const digitoVerificador = resto === 0 ? 0 : resto === 1 ? 9 : 11 - resto;

    if (digitoVerificador !== parseInt(numericCuit[10], 10)) {
      return "El dígito verificador del CUIT/CUIL no es válido.";
    }
  }
  return "";
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /^[a-zA-Z0-9._%+-ñÑáéíóúÁÉÍÓÚ]*@[a-zA-Z0-9.-]*\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const validateField = (
  field: "email" | "cuit",
  value: string
): string => {
  const validators: Record<"email" | "cuit", (value: string) => string> = {
    email: (value) => {
      if (!value.trim()) return "Completá tu email para ingresar.";
      if (!isValidEmail(value))
        return "Por favor, revisá tu email y volvé a intentar.";
      return "";
    },
    cuit: (value) => {
      if (!value.trim()) return "Completá tu CUIL/CUIT para ingresar.";
      const formattedCuit = formatCUIT(value);
      if (formattedCuit.length !== 13)
        return "Tu CUIL/CUIT debe tener 11 dígitos numéricos";
      return validarCuit(formattedCuit);
    },
  };

  return validators[field](value);
};
