import React from "react";
import { AlertWarning } from "./warningAlert.styles";

const WarningAlert = () => {
  return (
    <AlertWarning
      data-testid="warning-alert"
      message="Es necesario aceptar los términos y condiciones, para poder conectarse al WIFI."
      title=""
      variant="warning"
    />
  );
};

export default WarningAlert;
