import React, { useEffect } from "react";
import Error from "@flame-ui/icons/src/error";
import {
  ButtonError,
  Container,
  ContainerButton,
  SubtitleError,
  TitleError,
} from "./PageError.styles";
import { getViewTag } from "../../utils/getGTMtag";
import { useHistory } from "react-router-dom";

const PageError: React.FC = () => {
  const history = useHistory();
  const handleButtonClick = () => {
    history.push("/");
  };

  useEffect(() => {
    getViewTag({
      screen: "error_screen",
      signal: "screen_view",
    });
  });

  return (
    <>
      <Container>
        <Error
          color="rgba(255, 130, 0, 1)"
          size={5}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
        <TitleError size="titleS" bold>
          En este momento no podemos conectarte al WiFi
        </TitleError>
        <SubtitleError size="paragraphL">
          Por favor, volvé a intentar más tarde.
        </SubtitleError>
      </Container>
      <ContainerButton>
        <ButtonError onClick={handleButtonClick} size="small" variant="primary">
          {" "}
          Ir al inicio
        </ButtonError>
      </ContainerButton>
    </>
  );
};

export default PageError;
