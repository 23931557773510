import Typography from "@flame-ui/core/src/foundations/typography/Typography";
import styled from "styled-components";
import Button from "@flame-ui/core/src/buttons/button/Button";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 29px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
  gap: 30px;
  border-radius: 16px;
  background: white;
  box-shadow: none;
  margin: 0 auto;
  width: fit-content;

  form {
    width: 100%;
  }

  @media (min-width: 670px) {
    background: var(--3-neutral-00, #fff);
    box-shadow: 0px 0px 30px 12px rgba(216, 216, 233, 0.3);
    padding: 51px 92px;
  }
`;

export const SubtitleForm = styled(Typography)`
  text-align: center;
  font-family: "Santander Headline";
  font-size: 20px;
`;

export const Link = styled.a``;

export const ButtonForm = styled(Button)`
  display: flex;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: max-content;
  align-self: center;

  @media (max-width: 1224px) and (min-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 899px) and (min-width: 675px) {
    font-size: 12px;
  }

  @media (max-width: 674px) {
    font-size: 11px;
  }
`;

export const VisuallyHiddenLabel = styled.label`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
`;

export const TermsButton = styled.a`
  color: ${({ theme }) => theme.colors.accent["01"]};
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  // display: block;
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.label`
  padding: 0.5rem;
  display: block;
  margin-left: 8px;
`;

export const Recaptcha = styled.div``;

export const RecaptchaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
