import Alert from "@flame-ui/core/src/notifications/alert/Alert";
import styled from "styled-components";

export const AlertWarning = styled(Alert)`
  display: flex;
  width: 100%;
  height: max-content;
  padding: 11px 7px 11px 6px;
  align-items: flex-start;
`;
