import React, { createContext, useState, SetStateAction } from "react";

type TargetUrl = {
  targetUrl: string;
};
type GlobalStateType = {
  state: TargetUrl;
  setState: React.Dispatch<SetStateAction<TargetUrl>>;
};

export const GlobalState = createContext<GlobalStateType>({
  state: {
    targetUrl: "",
  },
  setState: () => {},
});

export const GlobalProvider: React.FC = ({ children }) => {
  const [state, setState] = useState({
    targetUrl: "",
  });

  return (
    <GlobalState.Provider value={{ state, setState }}>
      {children}
    </GlobalState.Provider>
  );
};
